@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&display=swap');

@font-face {
    font-family: 'tahomaregular';
    src: url('./../font/tahoma_regular_font-webfont.woff2') format('woff2'),
         url('./../font/tahomabd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tahomabold';
    src: url('./../font/tahomabd-webfont.woff2') format('woff2'),
         url('./../font/tahomabd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

} 

@font-face {
  font-family: 'Helvetica Bold';
  src: url('./../font/Helvetica Bold/Helvetica-Bold.eot');
  src: url('./../font/Helvetica Bold/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../font/Helvetica Bold/Helvetica-Bold.woff2') format('woff2'),
  url('./../font/Helvetica Bold/Helvetica-Bold.woff') format('woff'),
  url('./../font/Helvetica Bold/Helvetica-Bold.ttf') format('truetype'),
  url('./../font/Helvetica Bold/Helvetica-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
 
@font-face {
    font-family: 'Helvetica Neue';
    src: url('./../font/HelveticaNeue/HelveticaNeue.eot');
    src: url('./../font/HelveticaNeue/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
        url('./../font/HelveticaNeue/HelveticaNeue.woff2') format('woff2'),
        url('./../font/HelveticaNeue/HelveticaNeue.woff') format('woff'),
        url('./../font/HelveticaNeue/HelveticaNeue.ttf') format('truetype'),
        url('./../font/HelveticaNeue/HelveticaNeue.svg#HelveticaNeue') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url('./../font/HelveticaNeue Bold/HelveticaNeue-Bold.eot');
  src: url('./../font/HelveticaNeue Bold/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
  url('./../font/HelveticaNeue Bold/HelveticaNeue-Bold.woff2') format('woff2'),
  url('./../font/HelveticaNeue Bold/HelveticaNeue-Bold.woff') format('woff'),
  url('./../font/HelveticaNeue Bold/HelveticaNeue-Bold.ttf') format('truetype'),
  url('./../font/HelveticaNeue Bold/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
 
@font-face {
  font-family: 'Helvetica';
  src: url('./../font/Helvetica/Helvetica.eot');
  src: url('./../font/Helvetica/Helvetica.eot?#iefix') format('embedded-opentype'),
  url('./../font/Helvetica/Helvetica.woff2') format('woff2'),
  url('./../font/Helvetica/Helvetica.woff') format('woff'),
  url('./../font/Helvetica/Helvetica.ttf') format('truetype'),
  url('./../font/Helvetica/Helvetica.svg#Helvetica') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeue Medium';
  src: url('./../font/HelveticaNeue Medium/HelveticaNeueMedium.eot');
  src: url('./../font/HelveticaNeue Medium/HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'),
  url('./../font/HelveticaNeue Medium/HelveticaNeueMedium.woff2') format('woff2'),
  url('./../font/HelveticaNeue Medium/HelveticaNeueMedium.woff') format('woff'),
  url('./../font/HelveticaNeue Medium/HelveticaNeueMedium.ttf') format('truetype'),
  url('./../font/HelveticaNeue Medium/HelveticaNeueMedium.svg#HelveticaNeueMedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

