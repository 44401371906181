@mixin mob($breakpoint:768px) {
    @media (max-width: #{$breakpoint}
    ) {@content;}
}
    
.carousel {position:relative;margin:2rem auto;overflow:hidden;
    @include mob {width:100%;margin:0 auto 22px auto;}
    .carousel-inner {display:flex;transition:transform 0.5s ease-in-out;width:100%;}
    .carousel-item {min-width:100%;position:relative;img {width:100%;height:auto;display:block;}}
    .carousel-indicators {position:absolute;bottom:100px;left:50%;transform:translateX(-50%);display:flex;gap:0.5rem;
        button {width:12px;height:12px;border-radius:50%;border:none;background-color:#9a9193;cursor:pointer;
            @include mob {width:10px;height:10px;}
        &.active {background-color:#0056b3;}}
    }    
}