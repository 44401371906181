@charset "UTF-8";

//color
$clr-base:#151231;
$white: #fff;
$black: #000;
$light-black: #080808;
$light-black-2: #151231;
$light-black-3: #141414;
$grey: #1e1c1c;

$brand: #1d64d6;
$brand-1: #007bff;
$brand-2: #1D64D6;

$background-grey: #9b9b9b;

//font-family
$f-robo: "Roboto", serif;
$f-tahomaregular: "tahomaregular", serif;
$f-tahomabold: "tahomabold", serif;
$f-Helveticabold: "Helvetica Bold", serif;
$f-HelveticaNeue: "Helvetica Neue", serif;
$f-HelveticaNeue-Bold: "HelveticaNeue-Bold", serif;

//breakpoint
$xl-max:1199.98px;
$xl-min:1200px;
$lgl-max:1024px;
$lg-max:991.98px;
$lg-min:992px;
$md-max:767.98px;
$md-min:768px;
$sm-max:575.98px;
$sm-min:576px;
$xs-max:370px;

$green:#51c416;
$rose:#2e2e2e;
$grey-bar:#d8d8d8;
$grey-btn-bg: rgba(0,0,0,0.7);

 
$text-color: #333;
$secondary-color: #555;
$box-shadow-color: rgba(0, 0, 0, 0.1);

