@import './variable.scss';
.mainImage {
    position: relative;
}

.mainImage img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    filter: brightness(0.7);
    display: block;
}

.mainImage_Contant {
    position: absolute; bottom: 30px; left: 45px; max-width: 100%;padding-bottom: 20px;
    p { color: white; font-size: 22px; margin-bottom: 0; }
    h2{ color: white; font-size: 42px; font-weight: 900;margin: 0 auto;
}
}



@media screen and (max-width: 767px) {
    .mainImage img {
        max-height: 400px;
    }

    .mainImage_Contant {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        place-content: center;
        gap: 8px;
    }

    .mainImage_Contant p {
        font-size: 16px;
    }

    .mainImage_Contant h1 {
        font-size: 42px;
    }
}

// Contant

.content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 40px;
    padding: 60px 0;
}

.content p {
    text-align: center;
    margin-bottom: 18px;
    font-size: 17px;
}

.contentBox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    gap: 30px;
}

.contentBox .content1 {
    position: relative;
    background: #fff;
    padding: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    p{font-weight: 400;margin:0 auto;padding-top: 10px;
      b{font-weight: 700;display: block;}
    }
}

.contentBox .content1 img {
    width: 100%;
}

.contentBox .content1 button {
    position: absolute;
    right: 20px;
    bottom: 20px;
}
 

@media screen and (max-width: 767px) {
    .content {
        max-width: 400px;
        grid-auto-flow: row;
        display: grid;
        padding: 30px 16px;
    }

    .content p {
        width: 100%;
        font-size: 13px;
    }

    .contentBox {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }

    .contentBox .content1 {
        margin-bottom: 15px;
        b{text-align: center;}
    }

    .contentBox .content1 button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 11px 25px;
        font-size: 28px;
        border: none;
        transition: 0.5s;
        cursor: pointer;
    }
}

// Penel

/* Panel */
.panel {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 0px 40px 0px;
}

.panel img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    object-position: top;
    border-radius:0 0 30px 30px;
    border-top: 5px solid #007bff;
}

.Pcontant {
    padding: 22px;
    font-size: 18px;
    display: grid;
    gap: 8px;
    h2{font-size: 24px; font-family: $f-tahomabold;}
    p{margin: 0 auto 10px;font-weight: 400; padding-left: 0;
        small{color: #767676;}
    }
}

.Pcontant_user {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    
    img{display: block;border-radius: 130px;
        overflow: hidden;margin-right: 10px;}

    p {
        margin: 0;
    }

    h2 {
        margin: 0;
    }
}

.Pcontant_user img {
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 767px) {
    .panel {
        max-width: 400px;

        margin: 0 auto;
        padding: 60px 0px 40px 0px;
    }

    .panel img {
        width: 100%;
    }

    .Pcontant {
        display: grid;
        gap: 8px;
    }

    .Pcontant p {
        font-size: 14px;
    }

    .Pcontant_user img {
        width: 50px;
        height: 50px;
    }
}
/* about  Styles */
.about-banner{ margin: 0 auto; max-width: 100%; background-position: right top; max-width: 100%; background-size: 100% auto;  padding: 40px 380px 40px 40px; border-radius: 15px; background-repeat: no-repeat;
  background-color: rgb(64,190,220);
  h1{color: #fff; text-align: left; margin:0 0 20px;}
  p{color: #fff; text-align: left;font-size: 18px;font-weight: 400; margin: 0 auto;}

}
.about {
    padding: 50px 20px;
    max-width: 1280px;
    width: 100%;
    background: rgba(255,255,255,.7);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 50px auto;
    h2{font-weight: 600;}
    p{font-weight: 400;
      strong{
        color: #007bff;
        font-weight: 700;
      }
    }
    
    .card-row{display: flex; flex-wrap: wrap;
      .card{max-width: 50%; width: 50%; padding: 0 15px 30px;
        h2{font-weight: 600; margin-bottom: 10px;font-size: 24px;}
        p{font-size: 18px; line-height: 28px;}
      }
    }
    .title-row{margin-bottom: 60px;
      h2{font-size: 28px;font-weight: 700;color: #444}
    }
    .why-us{ justify-content: space-between;
      figure{max-width: 40%;}
      .caption-area{ max-width: 50%;
        ul{padding-left: 50px;
          li{display: block;font-size: 18px;
            &:before{content: '✓';display: inline-block; text-indent: -25px;}
          }
        }
      }
    }

    blockquote{border-left: 5px solid #007bff; padding: 40px; box-shadow: 2px 2px 8px rgba(0, 0, 0,.2); margin-top: 70px;}
}
.Contact {
    padding: 50px 20px;
    max-width: 900px;
    width: 90%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 50px auto;
}


/* Headings */
h1 {
    font-size: 36px;
    font-weight: 600;
    color: #222;
}

h2 {
    font-size: 28px;
    font-weight: 500;
    color: #444;
}

h3 {
    font-size: 22px;
    font-weight: 500;
    color: #555;
    margin-top: 20px;
}

/* Brand Name Styling */


/* Features List */
.features-list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;

    li {
        font-size: 18px;
        padding: 8px 0;
        display: flex;
        align-items: left;
        gap: 10px;
        justify-content: left;
    }
}

/* Thank You Section */

p {
    font-weight: bold;
    color: rgb(41, 41, 41);
    font-size: 16px;
    margin-top: 20px;
}


/* Responsive Design */
@media (max-width: 768px) {
    h1 { font-size: 30px; }
    h2 { font-size: 24px; }
    h3 { font-size: 20px; }
    .features-list li { font-size: 16px; }
}

/* policy css*/
.policy-container {
    background-color: #f8f9fa;
    color: #333;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .policy-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .policy-intro {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 1.6rem;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .policy-link {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  
  .advertisers-list {
    list-style-type: none;
    padding-left: 0;
    margin-left: 20px;
  }
  
  .advertisers-list li {
    margin-bottom: 10px;
  }
  .contact-container {
  text-align: center;
  font-family: "Sans-serif";
  background-color: #eee;

  .img-area{ max-width: 55%;}

}

h1 {
  color: black;
}

h2 {
  font-size: 23px;
}

.contact-info {
  justify-content: space-evenly;
  max-width: fit-content;
  margin: auto;
}

.contact-card {
  flex: 1;
  margin: 16px;
  text-align: center;
}

.contact-card img {
  height: 80px;
  width: 70px;
}

.thank-you {
  color: #2155CD;
  font-weight: bold;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
//

body {
    text-align: center;
  }
  
  .contact-container {
    margin: auto;
  }
  
  .contact-content {

    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    margin-bottom: 60px;
    .contact-info{margin: 0; padding: 0; padding: 30px 50px;
    }
  }
  
  .contact-info {
    flex: 1;
    text-align: left;
    
    .title-row{margin-bottom: 30px;
      p{color: #767676; line-height: normal;}
    }
    .contact-card{ margin: 0 auto;
      p{display: flex; align-items: flex-start;margin-bottom: 30px; font-weight: 400; 
        strong{font-weight: 400;}
        img{max-width: 45px; display: block;height: auto; padding-right: 15px; margin-top: -5px;}
        a{color: #222;}
      }
    }
  }
  
  .contact-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .contact-info p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .contact-info a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-image {
    flex: 1;
    text-align: right;
  }
  
  .contact-image img {
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
  }
  
  .contact-details {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .detail-card {
    flex: 1;
    padding: 15px;
    border-radius: 8px;
    margin: 0 10px;
    text-align: center;
  }
  
  .detail-card h3 {
    margin-bottom: 10px;
  }

  .detail-card p {
    margin-bottom: 10px;
  }
  
  .Pcontant p{
text-align: left;
padding-right: 10px;

  }
  
  
.stay-connected{ padding: 60px 0 0; max-width: 520px; margin: 0 auto;
  h2{text-align: center;}
  p{color: rgba(0, 0, 0,.7); word-break:break-word;
    font-size: 20px;
    margin-top: 28px;text-align: center;
  }
  .btn{display: inline-block;
    &:hover{color: $white; opacity: .9;}
  }
  .thank-you:last-child{padding-top: 30px;}
}

@media screen and (max-width: 767px){
  .contact-content{flex-wrap: wrap;display: block;
    .img-area{max-width: 100%; margin: 0 auto;}  
  }
  .contact-content .contact-info{padding: 10px 0;}
  .about-banner{ padding: 20px 30px 30px; background: rgb(64,190,220); background: radial-gradient(circle, rgba(64,190,220,1) 0%, rgba(40,148,183,1) 100%); 
    h1{font-size: 26px;margin-bottom: 10px;}
    p{font-size: 16px;}
  }
  .about .card-row{flex-wrap: wrap;
    .card{width: 100%; max-width: 420px; padding: 0 0 20px;
      h2{font-size: 20px; margin:0 auto 10px;}
      p{font-size: 16px; line-height: 26px; margin: 0 auto 10px;}
    }
  }
  .about {
    .why-us{max-width: 100%;flex-wrap: wrap;
      .caption-area,figure{width: 100%; max-width: 100%;}
      .caption-area{
        .features-list {padding-left: 40px;}
      }
    }
     blockquote{padding: 15px;margin-top: 40px;}
  }

}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .contact-content .contact-info{padding: 10px 20px;}
  .img-area{max-width: 48%;}
  .about-banner{ padding: 20px 240px 30px 30px;
    h1{font-size: 26px;margin-bottom: 10px;}
    p{font-size: 16px;}
  }
}