@mixin mob($breakpoint: 768px) {
  @media (max-width: #{$breakpoint}) {
    @content;
    }
}

html, body, div {margin:0; padding:0;}
html, body {font-family:$f-HelveticaNeue; font-size:14px; line-height:1.3334; color:$light-black; position:relative; color:$clr-base;}
body {-webkit-font-smoothing:antialiased;}

a {
color:#333131; cursor:pointer; text-decoration:none; transition:All .2s ease-in-out; &:hover {color:$brand; text-decoration:none;}
&.a-disabled {pointer-events:none;}
}

a:not([href]):not([tabindex]) {
cursor:pointer; &.a-disabled {pointer-events:none;}
}

*, :after, :before {box-sizing:border-box;}
h1, h2, h3, h4, h5, h6 {font-weight:700; margin:10px 0; font-family:$f-HelveticaNeue;}
h1, .h1 {font-size:67px; line-height:1.2; font-family:$f-tahomabold;}
h2, .h2 {font-size:20px;}
h3, .h3 {font-size:18px;}
h4, .h4 {font-size:17px;}
h5, .h5, h6, .h6 {font-size:16px;}
strong, b {font-weight:700;}
i {font-style:italic;}
p {margin-bottom:18px;}

ol {
margin:10px 0; padding-left:22px; li {padding-left:0; position:relative; margin-bottom:0; ol {margin:0;}}
}

ul {
list-style:none; margin:10px 0; padding:0; li {padding-left:24px; position:relative; margin-bottom:0; ul {margin:0;}}
}

sub, sup {font-size:10px;}

.container {
max-width:1120px; width:100%; padding:0 30px; margin:0 auto; @include mob {padding:0 0;}
}

.overflow-hidden {
overflow:hidden; .wrapper {margin-top:0;}
}
.block-row{padding: 40px 0;
  @include mob {padding:20px 0;}
}
.btn { padding:14px 30px; background-color:$brand-1; color:#fff; border:none; border-radius:5px; cursor:pointer; text-decoration:none; text-align:center; font-weight:700; justify-self:center; font-size:19px;
  &.btn-sm{padding: 5px 10px;font-size: 16px; background: rgba(19, 103, 254, .9);}
&.btn-gray {background:$grey-btn-bg; border-color:$grey-btn-bg; color:$white;}
&.btn-full {width:100%;}
&.btn-icon {border:none; background:transparent; padding:5px; img {display:block;}&:hover, &:focus, &:active {border:none; background:transparent;}
}
}
.btn-img{appearance: none; border: none; background: transparent;}

.wrapper {
display:flex; min-height:100vh; flex-direction:column; align-items:flex-start; background-image:url('./../images/main-bg.png'); background-size:100% auto; background-attachment:fixed; 
@include mob { background-size: auto 160%; background-repeat: no-repeat; background-attachment: fixed;}
header {width:100%; 
  .flex {align-items:center; justify-content:center;}
}
#content-area {flex:1; margin:0 auto; width:100%; >.container {flex:1; margin:0 auto; display:flex; flex:1;}
.content-area {display:block; margin:0 auto; flex:1; padding-left:30px; padding-right:30px;
  @include mob {padding-right: 15px;padding-left: 15px;}
  }
}
}
img{max-width: 100%;}

.flex {display:flex;}
.header {top:0; text-align:center; z-index:10; width:100%;}
// .header-content {display:flex; justify-content:flex-start; align-items:center; padding:30px 20px;}

.header-content {display: flex;
justify-content: space-between;
align-items: center;
padding: 30px 20px;
@include mob {padding: 10px 20px;}
}
.logo {font-size:18px; font-weight:bold; max-width: 210px;}
.hamburger {background:none; border:none; cursor:pointer; display:none; flex-direction:column; justify-content:space-between; height:20px; width:25px;}
.line {width:25px; height:3px; background-color:#333; transition:all 0.3s ease;}
.line.open:nth-child(1) {transform:translateY(8px) rotate(45deg);}
.line.open:nth-child(2) {opacity:0;}
.line.open:nth-child(3) {transform:translateY(-8px) rotate(-45deg);}

.main-nav {display:block; padding:0;}
.main-nav ul {display:flex; gap:20px;
 li {
    padding:0 13px; font-size:16px; font-weight:bold; color:#333; text-transform:capitalize; cursor:pointer; border-bottom:2px solid transparent; transition:all 0.3s ease; 
    a {font-size:18px; padding:10px 0; &:hover {color:#1d64d6; border-bottom:3px solid rgba(0, 0, 0, 0.25);}}
    &.active {color:#1d64d6; a {border-bottom:3px solid rgba(0, 0, 0, 1);}}
  }
}

footer{ background: #EFF1F5; padding: 50px 0; width: 100%;
  .flex{padding-left: 30px;padding-right: 30px; max-width: 1060px; margin: 0 auto;}
  .col{flex: 1; padding: 0 50px;
    @include mob {
    &:first-child{min-width: 100%;}
    }
    &:first-child{padding-left: 0;}
    &:last-child{padding-right: 0;padding-left: 0;}
  }
  h4{font-size: 25px;color: #121D31; margin: 0 auto; letter-spacing: 0.07em;margin-bottom: 10px;
    @include mob {font-size: 20px;}
  }
  .address-block{display: block;
    p{display: flex;font-size: 22px; align-items: flex-start;
      img{margin: 0 auto; max-width: 30px;margin-top: 3px;}  
      span{display: inline-block; vertical-align: top;padding-left: 10px; line-height: 28px;}
    }
  }  
  .link-area{display: block; 
    li{padding: 5px 0;display: block;
      a{color: #42536A;font-size: 22px;
        @include mob {font-size: 17px;}
        &:hover{color: $grey;}
      }
    }
  }
}
@media (max-width: 768px) {
.hamburger {display:flex;}
.main-nav {display:none; flex-direction:column; position:absolute; top:70px; right:0; background-color:#fff; box-shadow:0 4px 8px rgba(0, 0, 0, 0.1); width:200px; border-radius:5px; transition:transform 0.3s ease, opacity 0.3s ease; opacity:0; transform:translateY(-10px); 
  ul { margin: 0;
    li {text-align:center; 
    a{
      display: block;border-bottom: none !important;
    }  
  }
}
&.show {display: block; opacity: 1; top: 70px; width: 100%; left: 0;
  ul {display:flex; opacity:1; transform:translateY(0); display: block;}
}
}
.main-nav ul li {padding:5px 15px; text-align:left; border-bottom:1px solid #ccc;}
.main-nav ul:last-child {border-bottom:none;}

footer {
  .address-block {
    p{font-size: 16px;
      img{margin: 0;padding-right: 10px;margin-top: 7px;}
    }
  }
  .flex{flex-wrap: wrap;
  }
  .col{padding: 0;}
}
}