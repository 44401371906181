@mixin mob($breakpoint: 768px) {
    @media (max-width: #{$breakpoint}) {
        @content;
    }
}

.p-0 {
    padding: 0;
}

.info-section {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;

    @include mob {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        gap: 12px;
        margin-bottom: 22px;
    }

    h2,h5 {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 35px;
        font-weight: bold;
        font-family: Helvetica;
        margin-top: 5%;

        @include mob {
            margin: 0 0 6px;
            font-size: 24px;
        }
    }

    p {
        font-size: 20px;
        margin-top: 5%;
        font-weight: 500;
        color: #545F69;
        font-family: Helvetica;

        @include mob {
            margin: 0;
            font-size: 16px;
            margin-bottom: 0;
        }
    }

    .setimg {
        height: auto;
        max-width: 100%;
        width: 100%;
        border-radius: 5%;
    }
}

.image-box {
    flex: 0 0 45%;
    // max-width: 50%;
    box-sizing: border-box;

    @include mob {
        max-width: 100%;
        img{padding-top: 30px;}
    }
}

.image-box-one {
    @include mob {
        grid-row-start: 1;
    }
}

.image-box-five {
    @include mob {
        grid-row-start: 5;
    }
}