/* Variables */
@import '/src/scss/variable.scss'; 
@mixin mob($breakpoint: 768px) {
    @media (max-width: #{$breakpoint}) {
        @content;
    }
}

.icon-section {
    margin: 0 auto 32px auto;
    max-width: 1200px;
    padding: 30px;
    background-color: #EFF1F5;
    border-radius: 30px;

    @include mob {
        padding: 16px;
        border-radius: 24px;
        margin-bottom: 22px;
    }

    .icon-box { background-color: #fff; border-radius: 22px; padding:20px; box-shadow: 0 4px 8px $box-shadow-color; transition: transform 0.3s ease, box-shadow 0.3s ease; min-height: 230px;text-align: center;
        display: flex; flex-direction: column;
        @include mob { padding: 10px; min-height: 166px;
        }
        &:hover { transform: translateY(-5px); box-shadow: 0 8px 16px $box-shadow-color;
        }
        img {margin: 0 auto; flex: 1; max-width: 80px; height: auto; min-height: 105px; transform: scale(.7);
        }
        h3 { font-size: 22px; font-family: $f-HelveticaNeue; margin: 0 0 10px;
            @include mob { font-size: 20px; margin: 4px 0; }
        }

        p { font-size: 18px; color: $secondary-color; margin: 0;
            
        }
    }
}

/* Spacing for Grid */
.row { display: flex; flex-wrap: wrap;
    // gap: 20px;
}

.col-md-4 { flex: 1 1 calc(33.33% - 20px); }

/* Responsive Design */
@media (max-width: 768px) {
    .col-md-4 { flex: 1 1 calc(100% - 20px); }
}
